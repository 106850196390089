<template>
    <div>
        <video ref="video" width="100%" height="200px" autoplay v-if="open"></video>
        <v-btn v-if="open" color="success" @click="capturePhoto"> <v-icon>mdi-camera</v-icon> Tomar foto</v-btn>
        <v-btn v-if="!open" color="success" @click="setupCamera(indexCamara)"> <v-icon>mdi-camera</v-icon> Tomar otra foto</v-btn>
        <v-btn color="success" text @click="cambiarCamara">Cambiar camara</v-btn>
    </div>
</template>
<script>
export default {
    data(){
        return{
            camaras:[],
            stream :null,
            open:true,
            imagen:null,
            indexCamara:0
        }
    },
    beforeDestroy() {
        this.stopCamera()
    },
    async mounted(){
        await this.detectCameras()
        await this.setupCamera(this.camaras[this.indexCamara].deviceId)
    },
    methods:{
        async detectCameras() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                this.camaras = devices.filter((device) => device.kind === 'videoinput');
            } catch (error) {
                this.$notify({
                    group:'foo',
                    type:'error',
                    title:'ERROR',
                    text: `Error al detectar las cámaras: ${error}`
                })
                this.$emit('error')
            }
        },
        async setupCamera(cameraId) {
            try {
                this.stopCamera()
                this.open = true
                this.stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId:cameraId }});
                this.$refs.video.srcObject = this.stream;
            } catch (error) {
                console.error('Error al acceder a la cámara:', error);
            }
        },
        async cambiarCamara() {
            try {
                // const videoTracks = this.stream.getVideoTracks();
                // const nuevaFacingMode = (videoTracks[0].getSettings().facingMode === 'user') ? 'environment' : 'user';
                // const nuevasConstraints = { video: { facingMode: nuevaFacingMode } };
                // const newStream = await navigator.mediaDevices.getUserMedia(nuevasConstraints);
                // this.$refs.video.srcObject = newStream;

                // videoTracks.forEach((track) => track.stop());
                // this.stream = newStream;
                
                if(this.camaras.length>(this.indexCamara+1)){
                    this.indexCamara++
                }else{
                    this.indexCamara=0
                }
                this.setupCamera(this.camaras[this.indexCamara].deviceId)
            } catch (error) {
                console.error('Error al cambiar de cámara: ', error);
            }
        },
        stopCamera(){
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop());
                this.stream= null
                this.open = false
            }
        },
        async capturePhoto() {
            const canvas = document.createElement('canvas');
            canvas.width = this.$refs.video.videoWidth;
            canvas.height = this.$refs.video.videoHeight;
            canvas.getContext('2d').drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);
            const dataUrl = canvas.toDataURL('image/jpeg');
            this.imagen = this.base64toBlob(dataUrl.split(',')[1], 'image/jpg');
            this.$store.commit('setMessageOverlay', 'Optimizando firma, espere porfavor')
            this.$store.commit('setOverlay', true);
            const formData = new FormData()
            formData.append('firma',this.imagen)
            this.$http.post(`${this.$apiUrl}abogado-ciudadano/optimizar-firma`,
            formData, {
                headers: {"Content-Type": "multipart/form-data"} ,
                responseType: 'blob' 
            }).then(response=>{
                const headers = response.headers;
                const filename = headers['content-disposition'] && headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
                const contentType = headers['content-type'];
                const outside = URL.createObjectURL(response.data)
                this.url = outside
                this.$store.commit('setOverlay', false);
                this.$emit('capture',{
                    original:{
                        url:dataUrl,
                        archivo:this.imagen
                    },
                    procesado:{
                        url:this.url,
                        archivo:new File([response.data], filename, { type: contentType })
                    }
                })
            }).catch(error=>{
                console.log('------------------------------------');
                console.log(error);
                console.log('------------------------------------');
                this.$store.commit('setOverlay', false);
            })
            this.stopCamera()
            // Puedes detener la transmisión de la cámara después de capturar la foto si es necesario
            // this.stream.getTracks().forEach(track => track.stop());
        },
        base64toBlob(base64Data, contentType) {
            contentType = contentType || '';
            var sliceSize = 1024;
            var byteCharacters = atob(base64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: contentType });
        }
    }
}
</script>